<template>
    <el-row :gutter="20">
        <el-col :span="8">
            <el-card body-style="text-align:center;height:50px;line-height:50px;font-size:26px">
                <template #header>
                    <div class="card-header">
                        <span>
                            <el-icon>
                                <Money />
                            </el-icon>
                            今日交易金额
                        </span>
                        <span>(元)</span>
                    </div>
                </template>
                <span>{{ data.today_success_total > 0 ? data.today_success_total : '0.00' }}</span>
            </el-card>
        </el-col>
        <el-col :span="8">
            <el-card body-style="text-align:center;height:50px;line-height:50px;font-size:26px">
                <template #header>
                    <div class="card-header">
                        <span>
                            <el-icon>
                                <TrendCharts />
                            </el-icon>
                            今日交易笔数
                        </span>
                        <span>(笔)</span>
                    </div>
                </template>
                <span>{{ data.today_success_count > 0 ? data.today_success_count : 0 }}</span>
            </el-card>
        </el-col>
        <el-col :span="8">
            <el-card body-style="text-align:center;height:50px;line-height:50px;font-size:26px">
                <template #header>
                    <div class="card-header">
                        <span>
                            <el-icon>
                                <User />
                            </el-icon>
                            今日开户数量
                        </span>
                        <span>(个)</span>
                    </div>
                </template>
                <span>{{ data.today_merchant_count > 0 ? data.today_merchant_count : 0 }}</span>
            </el-card>
        </el-col>
    </el-row>
    <el-row :gutter="20" style="margin-top:20px">
        <el-col :span="8">
            <el-card body-style="text-align:center;height:50px;line-height:50px;font-size:26px">
                <template #header>
                    <div class="card-header">
                        <span>
                            <el-icon>
                                <Money />
                            </el-icon>
                            昨日交易金额
                        </span>
                        <span>(元)</span>
                    </div>
                </template>
                <span>{{ data.yesterday_success_total > 0 ? data.yesterday_success_total : '0.00' }}</span>
            </el-card>
        </el-col>
        <el-col :span="8">
            <el-card body-style="text-align:center;height:50px;line-height:50px;font-size:26px">
                <template #header>
                    <div class="card-header">
                        <span>
                            <el-icon>
                                <TrendCharts />
                            </el-icon>
                            昨日交易笔数
                        </span>
                        <span>(笔)</span>
                    </div>
                </template>
                <span>{{ data.yesterday_success_count > 0 ? data.yesterday_success_count : 0 }}</span>
            </el-card>
        </el-col>
        <el-col :span="8">
            <el-card body-style="text-align:center;height:50px;line-height:50px;font-size:26px">
                <template #header>
                    <div class="card-header">
                        <span>
                            <el-icon>
                                <User />
                            </el-icon>
                            昨日开户数量
                        </span>
                        <span>(个)</span>
                    </div>
                </template>
                <span>{{ data.yesterday_merchant_count > 0 ? data.yesterday_merchant_count : 0 }}</span>
            </el-card>
        </el-col>
    </el-row>
    <div ref="echartsRef" style="width:100%;height:300px;margin-top:30px;">

    </div>
</template>

<script setup>
import { Money, User, TrendCharts } from '@element-plus/icons-vue'
import { ref, onMounted, getCurrentInstance } from 'vue'
import * as echart from 'echarts'
const { proxy } = getCurrentInstance()
const data = ref({})
const echartsRef = ref(null)
onMounted(() => {
    proxy.$api.index.dashboard.statistics().then((r => {
        if (r.status == 200) {
            data.value = r.data
            const echarts = echart.init(echartsRef.value)
            const option = {
                legend: {},
                tooltip: {},
                dataset: {
                    dimensions: ['product', '交易金额', '交易笔数', '开户数量'],
                    source: [
                        {
                            product: '昨日',
                            '交易金额': data.value.yesterday_success_total,
                            '交易笔数': data.value.yesterday_success_count,
                            '开户数量': data.value.yesterday_merchant_count
                        },
                        {
                            product: '今日',
                            '交易金额': data.value.today_success_total,
                            '交易笔数': data.value.today_success_count,
                            '开户数量': data.value.today_merchant_count
                        }
                    ]
                },
                xAxis: { type: 'category' },
                yAxis: {},
                series: [{ type: 'bar' }, { type: 'bar' }, { type: 'bar' }]
            };

            echarts.setOption(option);
        }
    }))
})
</script>
<style scoped lang="less">
.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>